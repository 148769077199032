import React, { FC, useLayoutEffect, useState } from "react";
import { Modal } from "../../../common/components/modal/modal";
import { useIsMobileSize } from "../../../common/hooks/useIsMobileSize";
import { CloseButton } from "../../../common/components/modal/close-button";
import { defaultTheme } from "../../../../style/theme";
import {
  ButtonContainer,
  Container,
  ParentsItem,
  ParentsList,
  SubTitle,
  Text,
  Title,
} from "./modal-student-item-styles";
import { useUpdateStudentMutation } from "../../../../services/busy-bee-api/busy-bee-api-students";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../common/hooks/useAppSelector";
import { toggleModalStudentItem } from "../../store/students-slice";
import dayjs from "dayjs";
import { MainButton } from "../../../common/components/form/main-button";
import { StudentItemCalendar } from "./student-item-calendar/student-item-calendar";
import { SecondaryButton } from "../../../common/components/form/secondary-button";
import { selectInvoice } from "../../../invoices/store/invoices-slice";
import { StudentItemSelectedPaidDay } from "./student-item-selected-paid-day/student-item-selected-paid-day";
import { useGetAllResources } from "../../../../services/busy-bee-api/hooks/useGetAllResources";
import { visitingTypeOptions } from "../../constants";

const mobileWrapperStyles = {
  width: "100%",
  minHeight: "100%",
};

const ModalStudentItem: FC = () => {
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();

  const [selectedPaidDay, setSelectedPaidDate] = useState<
    dayjs.Dayjs | undefined
  >();
  const [visitedDates, setVisitedDates] = useState<Date[]>([]);

  const { isModalStudentItemOpen } = useAppSelector((state) => state.students);

  const [updateStudent] = useUpdateStudentMutation();

  const { teachers, invoices, students } = useGetAllResources();

  const student = students.find((item) => item._id === isModalStudentItemOpen);
  const teacher =
    teachers.find((teacher) => teacher._id === student?.teacherId)?.name || "";
  const studentInvoices = invoices.filter(
    (invoice) => invoice.studentId === student?._id,
  );

  const { selectedInvoice: selectedInvoiceGlobal } = useAppSelector(
    (state) => state.invoices,
  );

  useLayoutEffect(() => {
    const invoice = studentInvoices.find(
      (item) => item._id === selectedInvoiceGlobal,
    );
    if (!isModalStudentItemOpen) {
      setSelectedPaidDate(undefined);
      setVisitedDates([]);
      dispatch(selectInvoice(""));
    } else {
      setVisitedDates(student?.visitedDates ?? []);
      setSelectedPaidDate(
        selectedInvoiceGlobal ? dayjs(invoice?.paidDates[0]) : undefined,
      );
    }
  }, [isModalStudentItemOpen]);

  const onClose = () => {
    dispatch(toggleModalStudentItem(""));
  };

  if (!student) {
    return null;
  }

  const onSubmit = async () => {
    onClose();
    await updateStudent({
      _id: student._id,
      visitedDates: visitedDates.sort(),
    });
  };

  const selectedInvoice = studentInvoices.find((item) =>
    item.paidDates.find((date) =>
      selectedPaidDay
        ? dayjs(date).isSame(dayjs(selectedPaidDay), "day")
        : false,
    ),
  );

  const visitingDays = student.visitingDays;
  const paidDates = studentInvoices.map((item) => item.paidDates).flat();

  const onClickVisitDate = (value: Date) => {
    // const dayOfWeek = dayjs(value).locale("en").format("dd");
    // if (!visitingDays.includes(dayOfWeek)) {
    //   return;
    // }

    const filteredVisitedDates = visitedDates.find((date) =>
      dayjs(date).isSame(dayjs(value), "day"),
    );
    if (filteredVisitedDates) {
      setVisitedDates(
        visitedDates.filter((date) => !dayjs(date).isSame(dayjs(value), "day")),
      );
    } else {
      setVisitedDates([...visitedDates, value]);
    }
  };

  const onClickDate = (value: Date) => {
    const selectedInvoicePaidDate = selectedInvoice?.paidDates.find((date) =>
      dayjs(date).isSame(dayjs(value), "day"),
    );
    const paidDate = paidDates.find((date) =>
      dayjs(date).isSame(dayjs(value), "day"),
    );
    if (selectedPaidDay && selectedInvoicePaidDate) {
      setSelectedPaidDate(undefined);
      return;
    } else if (paidDate) {
      setSelectedPaidDate(dayjs(paidDate));
      return;
    } else {
      setSelectedPaidDate(undefined);
    }

    onClickVisitDate(value);
  };
  const statuses = `${!student.isUBD && !student.isVPO ? " відсутні" : ""}${student.isUBD ? " УБД" : ""}${student.isVPO ? " ВПО" : ""}`;
  const visitingTypeOption = visitingTypeOptions.find(
    (item) => item.value === student.visitingType,
  );
  const visitingType = visitingTypeOption?.label
    ? visitingTypeOption.label
    : " немає інформації";

  return (
    <Modal
      onClose={onClose}
      isOpen={!!isModalStudentItemOpen}
      wrapperStyles={isMobileSize ? mobileWrapperStyles : {}}
    >
      <Container>
        <CloseButton
          handleClose={onClose}
          color={defaultTheme.black}
          position="right"
        />
        <Title>{student.name}</Title>

        {!!student?.phone && (
          <Text>
            <b>Номер:</b> {student.phone}
          </Text>
        )}
        <Text>
          <b>Вчитель:</b> {teacher}
        </Text>
        <Text>
          <b>Статуси:</b>
          {statuses}
        </Text>
        <Text>
          <b>Тип відвідування: </b>
          {visitingType}
        </Text>

        {student.parents?.length > 0 ? (
          <>
            <Text>
              <b>Родичі</b>
            </Text>
            <ParentsList>
              {student.parents.map((item) => (
                <ParentsItem>
                  {item.name} {item.phone}
                </ParentsItem>
              ))}
            </ParentsList>
          </>
        ) : null}

        <SubTitle>Календар відвідувань та оплат</SubTitle>
        <StudentItemCalendar
          value={selectedPaidDay?.toDate()}
          visitingDays={visitingDays}
          visitedDates={visitedDates}
          paidDates={paidDates}
          selectedInvoicePaidDates={selectedInvoice?.paidDates}
          onClickDate={onClickDate}
        />

        {!!selectedPaidDay && selectedInvoice ? (
          <StudentItemSelectedPaidDay selectedInvoice={selectedInvoice} />
        ) : null}

        {/*<SubTitle>Платежі ({studentInvoicesSummary} грн)</SubTitle>*/}
        {/*<InvoicesList>*/}
        {/*  {studentInvoices.reverse().map((invoice, i) => (*/}
        {/*    <InvoiceItem>*/}
        {/*      <Text>*/}
        {/*        <b>{studentInvoices.length - i}. Сума:</b> {invoice.amount} грн*/}
        {/*      </Text>*/}
        {/*      <Text>*/}
        {/*        <b>Дата:</b>{" "}*/}
        {/*        {dayjs(invoice.createdAt).format("HH:mm DD.MM.YYYY")}*/}
        {/*      </Text>*/}
        {/*    </InvoiceItem>*/}
        {/*  ))}*/}
        {/*</InvoicesList>*/}
        <ButtonContainer>
          <SecondaryButton label="Закрити" onClick={onClose} />
          <MainButton label="Зберегти" type="submit" onClick={onSubmit} />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export { ModalStudentItem };
