export interface ISignInApi {
  username: string;
  password: string;
}
export interface ISignInResponseApi {
  accessToken: string;
}

export interface IStudentCreateApi {
  name: string;
  phone: string;
  teacherId: ITeacherApi | string;
  visitingDays: string[];
  visitedDates: Date[];
  isVPO: boolean;
  isUBD: boolean;
  visitingType: VisitingType;
  parents: { name: string; phone: string }[];
}
export interface IStudentUpdateApi {
  _id: string;
  name?: string;
  phone?: string;
  teacherId?: ITeacherApi | string;
  visitingDays?: string[];
  visitedDates?: Date[];
  isVPO?: boolean;
  isUBD?: boolean;
  visitingType?: VisitingType;
  parents?: { name: string; phone: string }[];
}
export interface IStudentApi {
  _id: string;
  name: string;
  phone: string;
  teacherId: ITeacherApi | string;
  parents: { name: string; phone: string }[];
  visitingDays: string[];
  visitedDates: Date[];
  isVPO?: boolean;
  isUBD?: boolean;
  visitingType?: VisitingType;
  createdAt: Date;
  updatedAt: Date;
}

export interface ITeacherCreateApi {
  name: string;
}
export interface ITeacherUpdateApi {
  _id: string;
  name: string;
}
export interface ITeacherApi {
  _id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum VisitingType {
  INDIV = 'individual',
  GROUP = 'group',
  INDIV_GROUP = 'individual_group'
}

export interface IInvoiceCreateApi {
  typeOfPayment: "card" | "cash" | "FOP";
  amount: number;
  dateOfPayment: Date;
  paidDates: Date[];
  studentId: IStudentApi | string;
  teacherId: ITeacherApi | string;
  createdAt?: Date;
}
export interface IInvoiceUpdateApi {
  _id: string;
  typeOfPayment?: "card" | "cash" | "FOP";
  amount?: number;
  dateOfPayment?: Date;
  paidDates: Date[];
  studentId?: IStudentApi | string;
  teacherId?: ITeacherApi | string;
  createdAt?: Date;
}
export interface IInvoiceApi {
  _id: string;
  typeOfPayment?: "card" | "cash" | "FOP";
  amount: number;
  dateOfPayment: Date;
  paidDates: Date[];
  studentId: IStudentApi | string;
  teacherId: ITeacherApi | string;
  createdAt: Date;
  updatedAt: Date;
}
