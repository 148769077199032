import React, { FC } from "react";

import { ReactComponent as PencilIcon } from "assets/streamline-light/interface-essential/edit/pencil.svg";

import {
  ActionsContainer,
  Container,
  DateContainer,
  Item,
  NameContainer,
  TeacherContainer,
} from "./students-list-styles";
import dayjs from "dayjs";
import { IStudent } from "../../interface";
import { IconButton } from "../../../common/components/form/icon-button";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import {
  toggleModalEditStudent,
  toggleModalStudentItem,
} from "../../store/students-slice";
import { visitingTypeOptions } from "../../constants";

interface IProps {
  students: IStudent[];
}

const StudentsList: FC<IProps> = ({ students }) => {
  const dispatch = useAppDispatch();

  const onEditClick =
    (id: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(toggleModalEditStudent(id));
    };

  const onStudentClick =
    (id: string) => (e: React.MouseEvent<HTMLLIElement>) => {
      e.stopPropagation();
      dispatch(toggleModalStudentItem(id));
    };

  return (
    <Container role="list" className="divide-y divide-gray-100">
      {students.map((item) => {
        const visitingTypeOption = visitingTypeOptions.find(
          (visitingTypeItem) => visitingTypeItem.value === item.visitingType,
        );
        const visitingType = visitingTypeOption?.label
          ? visitingTypeOption.shortLabel
          : "";
        return (
          <Item
            onClick={onStudentClick(item._id)}
            key={item._id}
            className="flex items-center justify-between gap-x-6 hover:bg-gray-50 cursor-pointer active:bg-gray-100 select-none"
          >
            <NameContainer>
              {item.name}
              {item.isUBD ? " УБД" : ""}
              {item.isVPO ? " ВПО" : ""}
              {visitingType ? ` ${visitingType}` : ""}
            </NameContainer>
            <TeacherContainer>{item.teacher}</TeacherContainer>
            <DateContainer>
              {dayjs(item.createdAt).format("HH:mm DD.MM.YYYY")}
            </DateContainer>
            <ActionsContainer>
              <IconButton
                onClick={onEditClick(item._id)}
                icon={PencilIcon}
                buttonColor={"#4F46E5FF"}
                buttonColorHover={"#3b34af"}
                color={"#fff"}
              />
              {/*<IconButton*/}
              {/*  icon={TrashIcon}*/}
              {/*  buttonColor={"#ff0000"}*/}
              {/*  buttonColorHover={"#b60000"}*/}
              {/*  color={"#fff"}*/}
              {/*/>*/}
            </ActionsContainer>
          </Item>
        );
      })}
    </Container>
  );
};

export { StudentsList };
